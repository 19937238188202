import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuService, MenuItemDetail } from '../main-menu.service';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { UiAvatarComponent } from '@bling-fe/shared/ui-components/ui-avatar';

@Component({
  selector: 'bling-fe-tabs-navigation-modal-item',
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    TranslateModule,
    UiAvatarComponent
  ],
  template: `
    <a
      *ngIf="itemType === 'internalLink'"
      [routerLink]="[item.url]"
      routerLinkActive="active"
      [attr.data-cy]="item.cypressId"
    >
      <svg-icon
        [key]="item.icon"
        size="xl"
      ></svg-icon>

      {{ item.label | translate }}
    </a>

    <a
      *ngIf="itemType === 'externalLink'"
      [href]="item.url"
      target="_blank"
    >
      <svg-icon
        [key]="item.icon"
        size="xl"
      ></svg-icon>

      {{ item.label | translate }}
    </a>

    <ng-container *ngIf="itemType === 'myProfile'">
      <a
        *ngIf="currentUser$ | async as user"
        [routerLink]="item.url"
        routerLinkActive="active"
        class="my-profile"
      >
        <bl-avatar
          [user]="user"
          [size]="36"
          [showOnlineIndicator]="false"
          slot="start"
        ></bl-avatar>

        {{ user.full_name }}
      </a>
    </ng-container>
  `,
  styles: [
    `
      a {
        color: var(--ion-color-dark);
        font-size: var(--font-md);
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px;

        &.active {
          color: var(--ion-color-primary);
        }

        &.my-profile {
          padding: 12px 16px;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsNavigationModalItemComponent {
  private store = inject(Store);
  private mainMenuService = inject(MainMenuService);

  @Input() item!: MenuItemDetail;

  currentUser$ = this.store.pipe(select(getCurrentUser));

  get itemType() {
    if (this.item === this.mainMenuService.STATIC_ITEMS.MY_PROFILE)
      return 'myProfile';
    if ('onClick' in this.item) return 'onClick';
    return this.item.url?.startsWith('/') ? 'internalLink' : 'externalLink';
  }
}
