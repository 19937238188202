import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { IonicModule } from '@ionic/angular';
import { marker as _ } from '@bling-fe/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { Swiper } from 'swiper/types';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';

interface Step {
  title: string;
  subtitle: string;
  image: string;
}

@Component({
  selector: 'bling-fe-ihz-member-feature-onboarding',
  imports: [CommonModule, RouterModule, IonicModule, TranslateModule],
  templateUrl: './ihz-member-feature-onboarding.component.html',
  styleUrls: ['./ihz-member-feature-onboarding.component.scss'],
  // because of the swiper
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IhzMemberFeatureOnboardingComponent implements AfterViewInit {
  @ViewChild('swiperRef', { static: true }) swiperRef: ElementRef | undefined;

  public steps: Array<Step> = [
    {
      title: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP1.TITLE'),
      subtitle: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP1.SUBTITLE'),
      image: 'assets/images/onboarding/team.svg'
    },
    {
      title: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP2.TITLE'),
      subtitle: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP2.SUBTITLE'),
      image: 'assets/images/onboarding/approval.svg'
    },
    {
      title: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP3.TITLE'),
      subtitle: _('IHZ_MEMBER.FEATURE_ONBOARDING.STEP3.SUBTITLE'),
      image: 'assets/images/onboarding/coworking-space.svg'
    }
  ];

  public currentSlide = 1;

  private router = inject(Router);

  ngAfterViewInit(): void {
    this.initializeSwiper();
  }

  private initializeSwiper() {
    register();
    // changedetection bug - without timeout only one slide appears
    setTimeout(() => {
      const swiper = this.swiperRef?.nativeElement;
      // assign custom styles
      const params = {
        injectStyles: [
          `
            .swiper-pagination-bullet {
              background-color: var(--ion-color-primary-tint);
              opacity: 1;
              width: 12px;
              height: 12px;
            }
            .swiper-pagination-bullet-active {
              background-color: var(--ion-color-primary);
            }
            .swiper-pagination-bullet-active ~.swiper-pagination-bullet {
              background-color: var(--ion-color-pattensblue)
            }
          `
        ]
      };

      Object.assign(swiper, params);

      swiper.initialize();
    }, 0);
  }

  slideChange(e: { detail: [Swiper] }) {
    this.currentSlide = e.detail[0].activeIndex + 1;
  }

  next() {
    if (this.currentSlide >= this.steps.length) {
      this.complete();
    } else {
      this.swiperRef?.nativeElement.swiper.slideNext();
    }
  }

  prev() {
    this.swiperRef?.nativeElement.swiper.slidePrev();
  }

  complete() {
    Preferences.set({
      key: STORAGE_KEYS.ONBOARDING_DONE,
      value: 'true'
    });
    this.router.navigate(['/']);
  }
}
