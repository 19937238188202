import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  CustomModalOptions,
  ModalAbstractComponent
} from '@bling-fe/shared/modals/base-modal';
import { CommonModule } from '@angular/common';
import { MenuItemDetail } from '../main-menu.service';
import { UiModalHeaderComponent } from '@bling-fe/ihz-member/ui-components/ui-modal-header';
import { TabsNavigationModalItemComponent } from './tabs-navigation-modal-item.component';

export interface TabsNavOptionsProps {
  tabs: MenuItemDetail[];
  modalTitle: string;
}
@Component({
  templateUrl: './tabs-navigation-modal.component.html',
  styleUrls: ['./tabs-navigation-modal.component.scss'],
  providers: [
    // for storybook
    {
      provide: ModalAbstractComponent,
      useExisting: TabsNavigationModalComponent
    }
  ],
  imports: [
    TranslateModule,
    IonicModule,
    CommonModule,
    UiModalHeaderComponent,
    TabsNavigationModalItemComponent
  ]
})
export class TabsNavigationModalComponent extends ModalAbstractComponent<
  TabsNavOptionsProps,
  boolean
> {
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'auto-height bottom-sheet-modal'
  };
}
