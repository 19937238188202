<ion-content>
  <div class="px-5 py-7 d-flex flex-col h-100">
    <img
      class="logo"
      src="/assets/images/ihz-logo.svg"
      alt="Logo"
    />
    <swiper-container
      #swiperRef
      init="false"
      pagination="true"
      class="flex-1 w-100 mt-6"
      (swiperslidechange)="slideChange($any($event))"
      [centeredSlides]="true"
    >
      <swiper-slide *ngFor="let step of steps">
        <article class="ion-text-center">
          <img
            [src]="step.image"
            aria-hidden="true"
            width="900px"
          />
          <h1 class="text--xl text--bold mt-0">
            {{ step.title | translate }}
          </h1>
          <h2 class="text--base text--normal mt-1 pb-8">
            {{ step.subtitle | translate }}
          </h2>
        </article>
      </swiper-slide>
    </swiper-container>
    <div class="d-flex mt-6">
      <ion-button
        color="primary"
        expand="block"
        fill="outline"
        class="w-100 mr-5"
        *ngIf="currentSlide > 1"
        (click)="prev()"
      >
        {{ "COMMON.LABELS.BACK" | translate }}
      </ion-button>
      <ion-button
        color="primary"
        expand="block"
        class="w-100 text--bold"
        (click)="next()"
      >
        {{ "COMMON.LABELS.NEXT" | translate }}
      </ion-button>
    </div>
    <a
      tabindex="0"
      class="no-underline text--bold ion-text-center mt-6"
      (click)="complete()"
      (keyup.enter)="complete()"
      (keyup.space)="complete()"
    >
      {{ "IHZ_MEMBER.FEATURE_ONBOARDING.SKIP" | translate }}
    </a>
  </div>
</ion-content>
