import { Injectable } from '@angular/core';
import { environment } from '@bling-fe/shared/env';
import { ScriptService } from 'ngx-script-loader';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private debugData = environment.production ? {} : { debug_mode: 1 };
  constructor(private scriptService: ScriptService) {}

  public init() {
    if (Capacitor.isNativePlatform()) {
      this.initAnalyticsMobile();
    } else {
      this.initGa4Web();
    }
  }

  public enable() {
    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.enable();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any)[`ga-disable-${environment.gaMeasurementId}`];
    }
  }

  public disable() {
    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.disable();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)[`ga-disable-${environment.gaMeasurementId}`] = true;
    }
  }

  private initGa4Web() {
    if (
      environment.gaMeasurementId != undefined &&
      environment.gaMeasurementId != ''
    ) {
      try {
        return this.scriptService
          .loadScript('../../../assets/js/ga4_script.js', {
            measurementId: environment.gaMeasurementId
          })
          .subscribe();
      } catch (error) {
        console.log('Make sure to provide a ga4_script.js file in app assets');
        throw error;
      }
    } else {
      return null;
    }
  }

  private initAnalyticsMobile() {
    if (environment.firebaseConfig != undefined) {
      FirebaseAnalytics.initializeFirebase({
        ...environment.firebaseConfig
      }).then(() => {
        // set debug mode to all events
        if (
          this.debugData.debug_mode &&
          environment.firebaseConfig != undefined
        ) {
          (window as any).gtag(
            'config',
            environment.firebaseConfig.measurementId,
            {
              debug_mode: true
            }
          );
        }
      });
    }
  }
}
