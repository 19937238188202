import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ih-modal-header',
  imports: [CommonModule, SvgIconComponent, TranslateModule],
  template: `
    <div class="flex--center p-4 border-bottom">
      <button
        (click)="dismiss.emit()"
        class="dismiss-btn"
      >
        <svg-icon
          key="delete-circle"
          size="xl"
        ></svg-icon>
      </button>
      <h1 class="text--md text--medium grow ion-text-center">
        {{ title }}
      </h1>
    </div>
  `,
  styles: [
    `
      .dismiss-btn {
        background: none;
        color: var(--ion-color-dark);
        line-height: 0;
        &:hover {
          color: #666667;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiModalHeaderComponent {
  @Output() dismiss = new EventEmitter<void>();
  @Input() title!: string;
}
