import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '@flink-legacy/core/declarations/user.interface';
import { UserRepository } from '@flink-legacy/shared/repositories/user.repository';
import { ResolverService } from '@flink-legacy/shared/services/resolver.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver {
  constructor(
    private userRepository: UserRepository,
    private resolverService: ResolverService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<User> {
    const id = Number(route.paramMap.get('id'));
    return this.resolverService.createResolver<User>(
      this.userRepository.findById(id),
      'user_profile'
    );
  }
}
